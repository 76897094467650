import Vue from 'vue'
import carModel from './car-model'
import router from '@/router'
let instance = null

const selector = (params, initData = []) => {
  const selectorCar = Vue.extend(carModel)
  console.log(params)
  if (!instance) {
    instance = new selectorCar({
      el: window.document.createElement('div'),
      data:()=>({ initData })
    })
    window.document.body.appendChild(instance.$el)
  }
  router.afterEach(to => {
    if (instance){
      instance.reject()
      instance.showPicker=false
    }
  })
  return new Promise((resolve, reject) => {
    instance.showPicker = true
    instance.loadData(params)
    instance.resolve = resolve
    instance.reject = reject
  })
    .finally(() => {
      window.setTimeout(() => {
        instance.$el.remove()
        instance.$destroy()
        instance = null
      }, 200)
    })
}

export default selector
