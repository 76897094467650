/** * 这是一个实现多级选择的组件 * 对外广播confirm和cancel事件 * props * list
数据数组 * */

<script>
import { Icon, Cell, CellGroup } from 'vant'

export default {
  components: {
    vanIcon: Icon,
    vanCell: Cell,
    vanCellGroup: CellGroup,
  },
  props: {
    list: {
      type: Array,
    },
    level: {
      type: Number,
      default: 0,
    },
    cellActive: {
      type: Object,
    },
  },
  data() {
    return {
      active: '',
    }
  },
  watch: {
    cellActive: {
      handler: function(val) {
        if (!val) return
        this.active = val
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onChange(item) {
      this.active = item
      this.$emit('change', { level: this.level, active: this.active })
    },
  },
}
</script>
<template>
	<van-cell-group>
		<van-cell
			v-for="(item, index) in list"
			:key="index"
			clickable
			border
			:title="item.name"
			:class="{ active: active === item }"
			@click="onChange(item)"
		>
			<template #right-icon>
				<van-icon
					v-show="active === item"
					name="success"
					size="22"
					color="#B9921A"
				/>
			</template>
		</van-cell>
	</van-cell-group>
</template>
<style lang="less" scoped>
.van-cell {
	&.active {
		color: @yellow-text;
	}
}
</style>
