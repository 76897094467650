<script>
import formSelectWrapCascader from './form-select-wrap-cascader'
import { standardTypeTree } from '@/services/talk-skill.js'

import { Toast } from 'vant'
import { i18n } from '@/main'
export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrapCascader,
  },
  data() {
    return {
      list: [],
      showPicker: false,
      loading: false,
      empty: false,
      initData: [],
      i18n,
    }
  },
  methods: {
    loadData(params) {
      const toast = Toast.loading({
        message: i18n.t('loading'),
        forbidClick: true,
      })
      this.loading = true
      standardTypeTree()
        .then((data) => {
          this.list = data
        })
        .finally(() => {
          toast.clear()
          this.loading = false
        })
    },
    onConfirm(value) {
      this.resolve(value)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    },
  },
}
</script>
<template>
	<form-select-wrap-cascader
		:columns="list"
		:showPicker="showPicker"
		:initData="initData"
		:title="i18n.t('请选择标准分类')"
		:loading="loading"
		:empty="empty"
		@confirm="onConfirm"
		@cancel="cancel"
	>
	</form-select-wrap-cascader>
</template>
