<template>
	<div class="talk-skill-edit-page">
		<van-form
			scroll-to-error
			style="border-radius: 8px;"
			input-align="right"
			error-message-align="right"
			@submit="onSubmit"
		>
			<van-field
				v-model="item.title"
				required
				maxlength="20"
				:label="$t('话术标题')"
				:placeholder="$t('请输入')"
				:rules="[{ required: true }]"
			/>
			<van-cell
				style="padding-bottom: 0;"
				:title="$t('话术内容')"
				required
				:border="false"
			/>
			<van-field
				v-model="item.content"
				input-align="left"
				:placeholder="$t('请输入')"
				:required="false"
				rows="3"
				autosize
				type="textarea"
				maxlength="1000"
				show-word-limit
				:rules="[{ required: true }]"
			/>
			<van-field
				:value="getClassifyName"
				required
				:label="$t('标准分类')"
				rows="1"
				autosize
				type="textarea"
				readonly
				:placeholder="$t('请选择')"
				:rules="[{ required: true }]"
				right-icon="arrow"
				@click="selectType"
			/>
			<van-field
				:value="getPersonalClassifyName"
				readonly
				:label="$t('个人分类')"
				:placeholder="$t('请选择')"
				type="textarea"
				rows="1"
				autosize
				right-icon="arrow"
				@click="
					$router.push(
						`/talk-skill-custom-classify?str=${getPersonalClassifyName}`
					)
				"
			/>
			<van-field
				:value="modelNames.join()"
				readonly
				:label="$t('适用车型')"
				:placeholder="$t('请选择')"
				type="textarea"
				rows="1"
				autosize
				right-icon="arrow"
				@click="selectCarModel"
			/>
			<van-field
				v-model="item.sysLabelName"
				:label="$t('关键词')"
				:placeholder="$t('请输入')"
				rows="1"
				autosize
				type="textarea"
				maxlength="50"
			/>
			<van-field
				v-model="item.personLabel"
				maxlength="10"
				:label="$t('个人标签')"
				:placeholder="$t('请输入个人自定义标签')"
			/>
			<div class="actions">
				<van-button @click.prevent="$router.back()">
					{{ $t('取消') }}
				</van-button>
				<van-button type="info" native-type="submit" color="#EED484">
					{{ $t('保存') }}
				</van-button>
			</div>
		</van-form>
		<van-popup
			v-model="showPicker"
			class="back-popup"
			get-container="#app"
			position="right"
			style="width:100%;height:100%"
		>
			<div id="header-popup">
				<div class="opt" @click="showPicker = false">
					<slot name="header-left">
						<van-icon name="arrow-left" size="18"></van-icon>
					</slot>
				</div>
				<div id="title">
					<slot name="header-title">{{ $t('请选择') }}</slot>
				</div>
				<div class="opt" @click="confirm">
					<slot name="header-right"> {{ $t('确认') }} </slot>
				</div>
			</div>

			<!-- 车系车型，级联选择器，新版 -->
			<BusinessCascaderBrandSeriesModels
				v-if="showPicker"
				v-bind="{
					field: 'cars',
					fields: ['seriesCodes', 'modelCodes'],
					defaultValue,
				}"
				@change="(values) => handleBrandCarsCascaderChange(values, item)"
			>
			</BusinessCascaderBrandSeriesModels>
		</van-popup>
	</div>
</template>
<i18n>
{
  "zh": {
		"确认": "确认",
		"取消": "取消",
		"保存": "保存",
		"话术标题": "话术标题",
		"请输入": "请输入",
		"请选择": "请选择",
		"话术内容": "话术内容",
		"标准分类": "标准分类",
		"适用车型": "适用车型",
		"关键词": "关键词",
		"个人标签": "个人标签",
		"请输入个人自定义标签": "请输入个人自定义标签",
		"个人分类": "个人分类"
	},
	"en": {
    "确认": "Confirm",
    "取消": "Esc",
		"保存": "Save",
		"话术标题": "Title",
		"请输入": "Please enter",
		"请选择": "Please select",
		"话术内容": "Content",
		"标准分类": "Classification",
		"关键词": "Keyword",
		"个人标签": "Personal labels",
		"请输入个人自定义标签": "Please enter",
		"个人分类": "Please enter"
	}
}
</i18n>
<script>
import BusinessCascaderBrandSeriesModels from '@/components/v2/business/BusinessCascaderBrandSeriesModels/index.vue'
import selectCommonType from './select-common-type'
import mixins from './mixins.js'
import {
  speechCraftUpdate,
  speechCraftAdd,
  speechCraftDetail,
} from '@/services/talk-skill.js'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading.js'
import eventBus from './event-bus.js'

export default {
  name: 'TalkSkillEdit',
  components: {
    BusinessCascaderBrandSeriesModels,
  },
  mixins: [mixins],
  data() {
    return {
      item: {
        speechCraftType: 2,
        id: this.$route.query.id,
        title: '',
        content: '',
        personLabel: '',
        sysLabelName: '',
        seriesCodes: [],
        modelCodes: [],
        personalClassify: [],
        classifyOneName: '',
        classifyTwoName: '',
        classifyThreeName: '',
        classifyFourName: '',
        classifyFiveName: '',
      },
      showPicker: false,
      modelNames: [],
      checkedObjectArray: [],
      defaultValue: [],
      checkedArray: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'TalkSkillEdit')
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!['/talk-skill-custom-classify'].includes(to.path)) {
      this.$store.commit('app/deleteKeepAlive', 'TalkSkillEdit')
    }
    next()
  },
  computed: {
    getPersonalClassifyName() {
      return this.item.personalClassify?.map(({ name }) => name)?.join() || ''
    },
    getClassifyName() {
      const record = this.item
      return [
        record.classifyOneName,
        record.classifyTwoName,
        record.classifyThreeName,
        record.classifyFourName,
        record.classifyFiveName,
      ]
        .filter((e) => e)
        .join('>')
    },
  },
  async beforeMount() {
    if (this.item.id) {
      Object.assign(this.item, await speechCraftDetail({ id: this.item.id }))
      const defaultValue = []
      const modelNames = []
      const list = await baseDataServices.getAllSeriesModels()
      seriesLoop: for (let i = 0; i < list.length; i++) {
        const { code, models } = list[i]
        console.log(list[i])
        if (this.item.seriesCodes.includes(code)) {
          for (let j = 0; j < models.length; j++) {
            const element = models[j]
            console.log(element)
            if (this.item.modelCodes.includes(element.code)) {
              defaultValue.push([code, element.code])
              modelNames.push(element.name)
            }
            if (defaultValue.length == this.item.modelCodes.length)
              break seriesLoop
          }
        }
      }
      this.modelNames = modelNames
      this.defaultValue = defaultValue
    }
  },
  beforeDestroy() {
    eventBus.$off()
  },
  mounted() {
    eventBus.$on('changeCustomClassify', (data) => {
      this.item.personalClassify = data
      console.log(data, 'data')
    })
  },
  methods: {
    handleBrandCarsCascaderChange(value, item) {
      console.info('handleBrandCarsCascaderChange', value, item)
      let { checkedObjectArray, checkedArray } = value
      this.checkedObjectArray = checkedObjectArray
      this.checkedArray = checkedArray
    },
    confirm() {
      this.showPicker = false
      const seriesCodes = []
      const modelCodes = []
      const modelNames = []
      this.checkedObjectArray.forEach(([f, s]) => {
        if (!seriesCodes.includes(f.value)) {
          seriesCodes.push(f.value)
        }
        modelNames.push(s.name)
        modelCodes.push(s.value)
      })
      this.item.seriesCodes = seriesCodes
      this.item.modelCodes = modelCodes
      this.modelNames = modelNames
      this.defaultValue = this.checkedArray
    },
    async selectType() {
      selectCommonType().then((res) => {
        const [
          { name: classifyOneName, id: classifyOne } = {},
          { name: classifyTwoName, id: classifyTwo } = {},
          { name: classifyThreeName, id: classifyThree } = {},
          { name: classifyFourName, id: classifyFour } = {},
          { name: classifyFiveName, id: classifyFive } = {},
        ] = res
        this.item.classifyOneName = classifyOneName
        this.item.classifyTwoName = classifyTwoName
        this.item.classifyThreeName = classifyThreeName
        this.item.classifyFourName = classifyFourName
        this.item.classifyFiveName = classifyFiveName
        this.item.classifyOne = classifyOne
        this.item.classifyTwo = classifyTwo
        this.item.classifyThree = classifyThree
        this.item.classifyFour = classifyFour
        this.item.classifyFive = classifyFive
      })
    },
    onSelect() {},
    async onSubmit() {
      loading.showLoading()
      const id = this.$route.query.id
      if (id) {
        await speechCraftUpdate(this.item)
      } else {
        await speechCraftAdd(this.item)
      }
      this.$toast.success(id ? this.$t('修改成功！') : this.$t('新增成功！'))
      this.$router.back()
    },
    selectCarModel() {
      this.showPicker = true
    },
  },
}
</script>
<style lang="less" scoped>
.talk-skill-edit-page {
	padding: 10px 16px;
	.actions {
		background: white;
		position: sticky;
		bottom: 0;
		padding: 16px 8px;
		display: flex;
		justify-content: space-around;
		button {
			color: #0d171a !important;
			border-radius: 4px;
			flex: 1;
			margin: 0 4px;
		}
	}
}
</style>
