<template>
  <div>
    <!-- <van-loading color="#1989fa" v-if="loading" /> -->
    <van-loading v-if="loading" size="24px">加载中...</van-loading>
    <div v-if="!loading && empty" class="empty">暂无数据</div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.empty{
  color: #969799;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
.van-loading {
  position: relative;
  color: #c8c9cc;
  font-size: 0;
  vertical-align: middle;
  text-align: center;
  margin-top: 50px;
}
</style>