/*
 * @Author: 张超越 
 * @Date: 2023-08-05 13:48:35 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-02-21 18:02:30
 */

<template>
  <div class="BusinessCascaderBrandSeriesModels">
    <CommonCascader
      :ref="`B_CommonCascader_${field}`"
      :items="cascaderData"
      :defaultActiveLeftKey="defaultActiveLeftKey"
      :defaultValue="defaultValue"
      :disableds="disableds"
      :cacheAllNodes="true"
      :nameClickCheck="nameClickCheck"
      @change="handleCascaderChange"
      @ready="handleCascaderReady"
      >
    </CommonCascader>
  </div>
</template>

<script>
// Components
import CommonCascader from '@/components/v2/system/CommonCascader'

// APIs
import baseDataServices from '@/services/baseDataServices'

// Tools
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'BusinessCascaderBrandSeriesModels',
  components: {
    CommonCascader
  },
  props: {
    brandMultiple: {
      // 品牌是否多选
      type: Boolean,
      default: true
    },
    field: {
      type: String,
      default: 'businessCascaderBrandSeriesModels'
    },
    nameClickCheck: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Array,
      default: () => [] // [[1,2,3], [4,5,6], [7,8,9]]
    },
    levels: {
      // 启用的层级，品牌 车系 车型 车款 对应 0 1 2 3， 默认 3
      type: Number,
      default: 3
    },
  },
  data() {
    return {
      cascaderData: [],
      disableds: [],
      defaultActiveLeftKey: '',
      cache: {
        checkedLength: 0,
        brands: null
      },
      searchKey: '',
    }
  },
  computed: {},
  watch: {},
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    reset() {
      this.$refs[`B_CommonCascader_${this.field}`].init()
    },
    async init() {
      // 检查levels
      if (this.levels < 0 || this.levels > 3) {
        throw new Error('levels must be 0, 1, 2, 3')
      }

      const brands = this.cache.brands || await baseDataServices.getAllSeriesModels()
      console.log('B_CASCADER_BRAND_CARS', brands)
      this.cascaderData = this.formatDatas(brands)
      this.defaultActiveLeftKey = this.cascaderData[0]?.value
      // 异步数据加载完成后设置默认值defaultValue
      this.$nextTick(()=>{
        this.reset()
      })
    },
    formatDatas(datas, searchKey) {
      return datas.map(item => {
        const onClick = (callBackItem) => {
          console.log('B onClick', callBackItem)
          // 多选
          if (this.brandMultiple) return 

          // 单选
          // 如果当前新状态为单选或者全选，则禁止再选其他选项，否则允许
          // if (callBackItem._check || callBackItem._half) {
          if (this.cache.checkedLength) {
            this.disableds = this.cascaderData.map(item => item.value).filter(value => value !== item.brandCode) // 从一级品牌开始禁用
          } else {
            this.disableds = []
          }
        }

        return {
          value: item.code,
          name: item.name,
          uuid: uuidv4(),
          onClick,
          children: this.levels <1 ? [] : (item.models || []).map(model => {
            return {
              value: model.code,
              name: model.name,
              uuid: uuidv4(),
              onClick,
            }
          })
        }
      })
    },
    handleCascaderChange(value) {
      console.log('B handleCascaderChange', value)
      this.cache.checkedLength = value.checkedValues.length || 0
      this.$emit('change', value)
    },
    handleCascaderReady() {
      this.$nextTick(() => {
        const refName = `B_CommonCascader_${this.field}`
        const refNode = this.$refs[refName]
        refNode && refNode.ON_DIV_SCROLL()
      })
    },

    // 对外暴露的方法
    CHECK_OFF_ITEM(tagValue) {
      console.log('CHECK_OFF_ITEM', tagValue)
      const refName = `B_CommonCascader_${this.field}`
      const refNode = this.$refs[refName]
      refNode.CHECK_OFF_ITEM(tagValue)
    },
    SEARCH(searchKey) {
      console.log('SEARCH', searchKey)
      this.searchKey = searchKey

      const handleItems = (items) => {
        for (let item of items) {
          if (item.children && item.children.length) {
            handleItems(item.children)
          }

          // 处理子级的父级，但凡有一个子级显示，父级就显示
          const childsSomeShow = item.children && item.children.length && item.children.some(item => !item._hiddenSelf)
          if (!searchKey || item.name.includes(searchKey) || childsSomeShow) {
            this.$set(item, '_hiddenSelf', false)

            // 展开子集
            this.$set(item, '_hiddenChilds', searchKey ? false : true)
          } else {
            this.$set(item, '_hiddenSelf', true)
          }

          console.log('_hiddenSelf', item._hiddenSelf)
        }
      }

      handleItems(this.cascaderData)

      const refName = `B_CommonCascader_${this.field}`
      const refNode = this.$refs[refName]
      refNode.RECOMPUTE_CHECK_HALF()
      refNode.SET_ACTIVE_LEFT_KEY()
    }
  }
}
</script>

<style lang="less" scoped>
.BusinessCascaderBrandSeriesModels {
  
}
</style>